import React, { Component } from 'react';

class Header_unauthorized extends Component {

  render () {
    return (
      <div className="Header_unauthorized">
        <div className="Container">
          <img className="Header__logo" src="/images/Logo-white.png" alt="" />
        </div>
      </div>
    );
  }
}

export default Header_unauthorized;